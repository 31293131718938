html,
body,
#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}

.clearfix { 
  display: inline-block;
}

* html .clearfix { 
  height: 1%; 
}

.clearfix { 
  display: block; 
}

.App {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: 0 15px 15px 15px;
  position: relative;
}
 
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.content-wrapper {
  width: 60%;
  margin: 0 auto;
  max-height: 100%;
  position: relative;
  text-align: left;
  margin-bottom: 50px;
}
 
.App-header {
  background-color: steelblue;
  height: 70px;
  padding: 20px;
  color: white;
}

.img-logo {
  max-width: 125px;
  border-radius: 50%;
  margin-top: 30px; 
}

.App-title {
  font-family: 'PT Sans Narrow','Tahoma',sans-serif; 
  font-size: 4em;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 25px;
}

.App-title span {
  font-size: .5em;
  display: block;
  margin-top: 10px;
}
 
.App-intro {
  font-size: large;
}
 
/* new css for movie component */
* {
  box-sizing: border-box;
}

.text-center {
  text-align: center;
}
 
.movies {
  display:flex;
  justify-content:space-between;
}
 
.App-header h2 {
  margin: 0;
}
 
.movie { 
  width: 33%;
  margin: 10px;
}

.movie img {
  border: 1px solid #bbb7b7;
}

.main-img {
  border: 1px solid #bbb7b7;
}

.movie:nth-child(3n+1) {
    margin-left : 0;
}
.movie:nth-child(3n) {
    margin-right : 0;
}

.TopBar {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    width: 100%;
    height: 62px;
    padding: 0 24px;
}
.TopBar a {
    margin-right: 18px;
    text-decoration: none;
}
.animated-page-wrapper {
    position: absolute;
    top: 62px;
    left: 0;
    width: 100%;
    height: 100%;
}
.page {
    padding: 0 24px;
}

/*
Project Template
*/ 

.width-50 {
  width: 50%;
  float: left;
}

.left {
  padding-left: 20px;
}
.project-info {
  margin-top: 0;
  margin-bottom: 20px;
}

.project-info a {
    color: #ff5700;
    transition: color 0.3s ease;
}
.project-info a:hover {
    color: #000;
    transition: color 0.3s ease;
}

.back-arrow {
  text-decoration: none;
  color: #000;
  font-size: 30px;
}

.built-with {
  padding-left: 0;
  list-style: none;
}

.built-with li {
  margin-bottom: 15px;
  font-weight: bold;
}

.view-site {
  text-align: center;
  color: white;
  background: black;
  padding: 15px 20px;
  text-decoration: none;
  margin-top: 30px;
  display: block;
  width: 200px;
}

@media(max-width: 1150px) {
  .content-wrapper {
    width: 80%;
  }
}

@media(max-width: 859px) {
  .content-wrapper {
    width: 100%;
  }
  .App-title {
  font-size: 2.5em;
  }

  .App-title span {
    font-size: .5em;
  }
}

@media(max-width: 600px) {

  .width-50 {
    width: 100%;
    float: none;
    padding-left: 20px;
    padding-right: 20px;
  }
  .App-title {
    font-size: 2em;
  }

  .App-title span {
    font-size: .5em;
  }
}






