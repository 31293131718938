.worksTransition-appear{
  opacity: 0;
  transform: translateX(-100px);
}

.worksTransition-appear.worksTransition-appear-active{
  opacity: 1;
  transform: translateX(0px);
  transition: all .4s ease-in-out;
}
